import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  toggle(event) {
    event.preventDefault()
    if (this.outputTarget.style.display === "none") {
        this.outputTarget.style.display = "block";
    } else {
        this.outputTarget.style.display = "none";
    }
  }
}
